<template>
  <div id="app">
    <panel-c-s />
    <scroll-to-top />
  </div>
</template>

<script>
import PanelCS from '@/components/PanelCS'
import ScrollToTop from '@/components/ScrollToTop'

export default {
  name: 'App',
  components: {
    PanelCS,
	ScrollToTop
  },
}
</script>

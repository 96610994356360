<template>
  <section class="panelcs">
    <div class="container">
      <b-card class="content">
        <b-img
            :src="logoImg"
            width="250px"
            class="logo"
            alt="logo" />
        <b-card-text>Silahkan pilih kontak pengaduan yang akan Anda hubungi</b-card-text>
        <ul>
          <li>
            <b-link
                href="tel:0211500853"
                target="_blank"
                rel="noreferrer">
                <div><i class="fas fa-phone"></i>&nbsp; Phone : 1500853</div>
                <div><i class="fas fa-arrow-right"></i></div>
              </b-link>
          </li>
          <li>
            <b-link
                href="https://wa.me/6281113015000"
                target="_blank"
                rel="noreferrer">
              <div><i class="fab fa-whatsapp"></i>&nbsp; WhatsApp : 081113015000</div>
              <div><i class="fas fa-arrow-right"></i></div>
            </b-link>
          </li>
          <li>
            <b-link
              href="mailto:cs@upoint.co.id"
              target="_blank"
              rel="noreferrer">
              <div><i class="fas fa-envelope"></i>&nbsp; Email : cs@upoint.co.id</div>
              <div><i class="fas fa-arrow-right"></i></div>
            </b-link>
          </li>
          <li>
            <b-link
                href="https://upoint.id/main?cat=history"
                target="_blank"
                rel="noreferrer">
              <div><i class="fas fa-window-maximize"></i>&nbsp; Web Care</div>
              <div><i class="fas fa-arrow-right"></i></div>
            </b-link>
          </li>
        </ul>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard, BCardText, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  data() {
    return {
      logoImg: require('@/assets/images/logo.png'),
    }
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
  },
  directives: {
    Ripple,
  },
}
</script>
